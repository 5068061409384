
  import { Component, Vue } from "vue-property-decorator";
  import { appName } from "@/env";
  import { readLoginError } from "@/store/main/getters";
  import { dispatchLogIn } from "@/store/main/actions";

  @Component
  export default class Login extends Vue {
    public email = "";
    public password = "";
    public appName = appName;
    public showPass = false;

    public get loginError() {
      return readLoginError(this.$store);
    }

    public submit() {
      dispatchLogIn(this.$store, { username: this.email, password: this.password });
    }
  }
